<template>
  <div class="bg-grey">
    <b-container fluid class="py-3 mt-xl-5 job-listings">
      <div class="job-search-bar mt-4">
        <div class="selector-wrapper job-selector-wrapper search-block">
          <input type="text" class="form-control"
            :placeholder="$cookies.get('role_id') != 2 ? 'Search jobs' : 'Search Candidates'" @keyup.enter="getAllJobs"
            v-model="search" />
        </div>

        <div class="selector-wrapper location-selector-wrapper location-block custom-select-2">
          <multiselect v-model="selectedCountry" placeholder="Select Country" label="checkbox" track-by="checkbox_id"
            :options="countries" @input="onSelectCountry" :multiple="false" />
        </div>

        <div class="search-button">
          <button class="btn btn-job-search" @click="() => {
            queryCountry = false;
            getAllJobs();
          }
            ">
            SEARCH
          </button>
        </div>
      </div>
      <!--End Job Search Bar-->

      <div class="job-sort-bar py-2 mt-3 mb-2">
        <div class="col-md-12 col-xl-12 left">
          <!-- <h6 class="fpn-b">{{ appliedJobs.length }} Jobs Applied</h6> -->
          <div class="d-xl-none mobile-filter">
            <button class="btn btn-mobile-filter mb-3" @click="showFilters"><b-icon icon="funnel-fill"
                @click="showFilters" class="mobile-filter-icon" width="20px"></b-icon>FILTERS</button>
          </div>
        </div>
        <div class="col-md-12 col-xl-12 right">
          <div class="ml-auto pr-3" style="cursor: pointer custom-select-2">
            <img src="@/assets/app-icons/sort-amount-down-alt-solid.svg" alt="" height="16px" width="16px" />
            Sort By
          </div>
          <div>
            <multiselect :options="sortOptions" placeholder="Sort By" label="label" track-by="label" :multiple="false"
              v-model="sort" @input="sortJobs" />
          </div>
        </div>
      </div>
      <!--End Job Sort Bar-->

      <div class="row">
        <div class="col-md-12 col-lg-12 col-xl-3">
          <div class="col-filter-box" :class="{ isMobileFilter }">
            <div class="text-right d-xl-none">
              <svg @click="hideFilters" width="3rem" height="3rem" viewBox="0 0 16 16" class="text-body bi bi-x"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </div>
            <!-- v-show="advanceSearch" -->
            <div class="sub-heading">
              <h5 class="mb-0">Filters</h5>
            </div>
            <!-- v-show="advanceSearch" -->
            <div>
              <div v-for="(data, index) in filters" :key="index">
                <div :class="data.checkbox && data.checkbox.length ? 'collapse-card col-filter-collapse' : ''">
                  <div
                    v-if="data.title != 'Shortlist' && data.title != 'Work From Home Jobs' && data.checkbox && data.checkbox.length"
                    class="filter-tab-title" v-b-toggle="'collapse-' + index">
                    {{ data.title }}
                  </div>
                  <div v-if="data.title == 'Work From Home Jobs'"
                    class="d-flex justify-content-between align-items-center"
                    style="padding: 0.5rem 0.5rem 0.5rem 0.8rem">
                    <span class="filter-tab-title">{{ data.title }}</span>
                    <b-form-checkbox v-model="wfh" @change="getAllWfhJobs" switch> </b-form-checkbox>
                  </div>

                  <div v-if="data.title == 'Shortlist' && $cookies.get('role_id') == 3"
                    class="d-flex justify-content-between align-items-center"
                    style="padding: 0.5rem 0.5rem 0.5rem 0.8rem">
                    <span class="filter-tab-title">{{ data.title }}</span>
                    <b-form-checkbox v-model="bookmarkFilter" @change="getAllBookmarkJobs" switch></b-form-checkbox>
                  </div>

                  <b-collapse :id="'collapse-' + index" class="pt-2">
                    <div class="flex-between mb-1" v-for="(check, i) in data.checkbox" :key="i">
                      <span class="filter-tab-text">{{ check.checkbox }}</span>
                      <b-form-checkbox v-model="appliedFilter.filters" :value="check" @change="onChangeCheckbox">
                      </b-form-checkbox>
                    </div>
                  </b-collapse>
                </div>
              </div>

              <div class="flex-between col-filter-btn">
                <button type="button" class="btn btn-reset" @click="resetFilters">RESET</button>
                <button type="button" class="btn btn-apply" @click="() => {
                  queryCountry = false;
                  getAllJobs();
                }
                  ">
                  APPLY
                </button>
              </div>
            </div>
          </div>
          <!--End col-filter-box-->
        </div>
        <!--End col-md-3-->

        <div class="col-md-12 col-lg-12 px-0 px-lg-2" :class="true ? 'col-xl-9' : ''">
          <div class="not-found-div" v-if="!jobLength">
            <h1>Your search produced no results</h1>
            <!-- <h4>Try adjusting to display better results</h4> -->
          </div>
          <div class="job-post-card" v-for="(job, index) in jobs" :key="index">
            <div class="job-post-header">
              <div class="left">
                <div style="cursor: pointer" @click="() =>
                  $router.push({
                    name: 'Job-Details',
                    params: { id: job.job_id },
                  })
                  ">
                  <img :src="job.company_logo_path" width="50" />
                </div>
                <div>
                  <router-link :to="`/job-details/${job.job_id}`">
                    <span>{{ job.company_long_name }}</span>
                  </router-link>
                </div>
                <div class="d-flex align-items-center">
                  <img src="@/assets/app-icons/clock.svg" width="16px" />
                  <span class="pl-1"> {{ job.daysAgo }}d ago </span>
                  <!-- <span class="ml-5">Deadline {{ job.deadline }}</span> -->
                </div>
              </div>
            </div>
            <div class="job-post-details">
              <h5 class="job-title mb-0" style="text-transform: capitalize">
                {{ job.job_title }}
              </h5>
              <div>
                <h6 class="heading">Salary (Annually)</h6>
                <div class="d-flex">
                  <div class="mr-2">{{ job.currency_code }}</div>
                  <div class="pr-2">
                    {{ numberWithCommas(job.job_salary_range_from) }}
                  </div>

                  <div class="pr-2">-</div>
                  <div class="mr-2">{{ job.currency_code }}</div>
                  <div>{{ numberWithCommas(job.job_salary_range_to) }}</div>
                </div>
                <!-- <p>
                  {{
                    `${job.job_salary_range_from} - ${job.job_salary_range_to}`
                  }}
                </p> -->
              </div>
              <div>
                <h6 class="heading">Job Type</h6>
                <p>{{ job.job_type }}</p>
              </div>
              <div>
                <h6 class="heading">Location</h6>
                <div class="d-flex job-location">
                  <p v-for="c in job.city" :key="c.city_id">
                    {{ c.city_name }}
                  </p>
                </div>
              </div>
              <div>
                <button v-if="$cookies.get('role_id') == 3" class="btn btn-match" :disabled="true" :style="100 <= job.match_percentage && job.match_percentage <= 75
                  ? 'background-color:#5ACEB1 !important'
                  : 50 <= job.match_percentage && job.match_percentage <= 74.99
                    ? 'background-color:#5A5AFF !important'
                    : 25 <= job.match_percentage && job.match_percentage <= 49.99
                      ? 'background-color:#FF8C00 !important'
                      : (24.99 >= job.match_percentage && job.match_percentage >= 0) || !job.match_percentage
                        ? 'background-color: #FF5A5A !important'
                        : ''
                  " v-b-tooltip.hover :title="`basic: ${JSON.stringify(job.basic_points_constitute)}
                                 video_profile: ${job.video_profile_points}
                                 vi: vi_weightage=${job.virtual_interview_points}% of score=${job.score_gained} -&gt; ${job.virtual_interview_points}
                                 vvi: vvi_weightage=${job.virtual_video_interview_weightage}% of point_scored=${job.video_point_scored} -&gt; ${job.virtual_video_interview_points}
                                eq: eq_weightage=${job.eq_template_weightage}% of eq_score=${job.eq_template_score} -&gt; ${job.eq_template_points}
                    `">
                  {{ job.match_percentage ? job.match_percentage.toFixed(2) : 0 }}
                  % Match &nbsp;<img v-b-tooltip.hover
                    title="The match rated is calculated based on your profile and the job details, if you want to increase your match rate, make sure to answer more questions which are suggested to you in your profile page"
                    src="@/assets/app-icons/question-mark.svg" />
                </button>
              </div>
            </div>
            <div class="job-post-description">
              <h6 class="desc-title">Description</h6>
              <p class="desc-content mb-0" style="white-space: pre-line">
                {{ job.job_description }}
              </p>
            </div>

            <div class="job-post-footer">
              <div class="left">
                <div class="share-text mr-4" @click="share(job)" v-b-modal.share-modal>
                  <span>Share</span>
                  <!-- <ShareNetwork
                    network="whatsapp"
                    :url="`${SERVERURL}job-details/${job.job_id}`"
                    :title="job.job_title"
                    :description="job.job_description"
                  >
                    Share
                  </ShareNetwork> -->
                  <img src="@/assets/app-icons/share.svg" width="16px" class="ml-2 mb-0" />
                </div>
                <div class="share-text" @click="AddBookmark(job.job_id, index)" style="cursor: pointer"
                  v-show="$cookies.get('role_id') == 3">
                  <span> SHORTLIST </span>
                  <img v-if="!job.bookmark" src="@/assets/app-icons/bookmark.svg" width="15px" class="ml-2" />
                  <img v-if="job.bookmark" src="@/assets/app-icons/yellow-bookmark.svg" width="15px" class="ml-2" />
                </div>
              </div>
              <div class="right">
                <router-link class="btn btn-job-details" :to="`/job-details/${job.job_id}`"> VIEW JOB DETAILS
                </router-link>
                <!-- <button
                  v-show="!job.applied"
                  class="btn btn-job-details"
                  @click="
                    $router.push({
                      path: `/job-details/${job.job_id}`,
                    })
                  "
                >
                  VIEW JOB DETAILS
                </button> -->
                <button class="btn btn-job-details btn-primary"
                  v-show="($cookies.get('role_id') == 3 || !$cookies.get('role_id')) && job.applied" @click="
                    $router.push({
                      name: 'Applicant-Answer',
                      params: { job_id: job.job_id },
                    })
                    ">
                  VIEW APPLICATION
                </button>
                <button class="btn btn-apply"
                  v-show="($cookies.get('role_id') == 3 || !$cookies.get('role_id')) && !job.applied"
                  @click.prevent.stop="onApplyJob(job)">APPLY</button>
              </div>
            </div>
          </div>
          <div class="flex-between jobs-navigation pb-3" v-if="jobs.length >= 1">
            <div>
              <button v-show="pagination.current != 1" :disabled="pagination.current == 1" class="btn btn-back"
                @click="PreviousPage">Back</button>
            </div>
            <div>
              <button v-show="pagination.current * pagination.pageSize < totalCount"
                :disabled="pagination.current * pagination.pageSize >= totalCount" class="btn btn-next"
                @click="nextPage">Next</button>
            </div>
          </div>
          <b-modal ref="myModal" hide-header hide-footer>
            <div class="text-center">
              <b-spinner label="Loading..." style="width: 8rem; height: 8rem"> </b-spinner>
              <h2>Please Wait Job Is Loading...</h2>
            </div>
          </b-modal>
        </div>
        <b-modal id="share-modal" ref="modal" @hidden="resetModal" @ok="handleOk" hide-footer>
          <div>
            <h4>Share this job on social network</h4>
            <div class="social-icons-grid">
              <ShareNetwork network="Whatsapp" :url="`${SERVERURL}job-details/${selectedJob.job_id}`"
                :title="selectedJob.job_title ? selectedJob.job_title : ''"
                :description="selectedJob.job_description ? selectedJob.job_description : ''" @open="handleSubmit">
                <div>
                  <i class="fa fa-whatsapp"></i>
                </div>
              </ShareNetwork>
              <ShareNetwork network="Facebook" :url="`${SERVERURL}job-details/${selectedJob.job_id}`"
                :title="selectedJob.job_title ? selectedJob.job_title : ''"
                :description="selectedJob.job_description ? selectedJob.job_description : ''" @open="handleSubmit">
                <div>
                  <i class="fa fa-facebook"></i>
                </div>
              </ShareNetwork>
              <ShareNetwork network="Twitter" :url="`${SERVERURL}job-details/${selectedJob.job_id}`"
                :title="selectedJob.job_title ? selectedJob.job_title : ''"
                :description="selectedJob.job_description ? selectedJob.job_description : ''" @open="handleSubmit">
                <div>
                  <i class="fa fa-twitter"></i>
                </div>
              </ShareNetwork>
              <ShareNetwork network="Linkedin" :url="`${SERVERURL}job-details/${selectedJob.job_id}`"
                :title="selectedJob.job_title ? selectedJob.job_title : ''"
                :description="selectedJob.job_description ? selectedJob.job_description : ''" @open="handleSubmit">
                <div>
                  <i class="fa fa-linkedin"></i>
                </div>
              </ShareNetwork>
            </div>
          </div>
        </b-modal>
      </div>
    </b-container>
    <b-modal :ref="'modal'" size="lg" modal-class="job-modal">
      <div slot="modal-header">
        <div class="d-flex align-items-center border-right pr-2">
          <button class="btn btn-danger fs-12" :style="100 <= selectedJob.match_percentage && selectedJob.match_percentage <= 75
            ? 'background-color:#5ACEB1 !important'
            : 50 <= selectedJob.match_percentage && selectedJob.match_percentage <= 74.99
              ? 'background-color:#5A5AFF !important'
              : 25 <= selectedJob.match_percentage && selectedJob.match_percentage <= 49.99
                ? 'background-color:#FF8C00 !important'
                : (24.99 >= selectedJob.match_percentage && selectedJob.match_percentage >= 0) || !selectedJob.match_percentage
                  ? 'background-color: #FF5A5A !important'
                  : ''
            " :disabled="true">
            {{ selectedJob.match_percentage }}% Match &nbsp;<img src="@/assets/app-icons/question-mark.svg"
              width="10px" />
          </button>
        </div>
        <div class="border-right pr-2">
          <img :src="selectedJob.company_logo_path" width="50" height="50" />
          <p class="fs-12 f-light mb-0">{{ selectedJob.company_long_name }}</p>
        </div>
        <div class="border-right pr-2">
          <h6 class="fmp-b">{{ selectedJob.job_title }}</h6>
          <p class="fs-12 f-light mb-0"><i class="fa fa-clock-o mr-1"></i>15d ago</p>
        </div>
        <div class="border-right pr-2">
          <h6 class="f-light fs-13">Salary(Annually)</h6>
          <h6 class="mb-0">{{ selectedJob.currency_code }}{{ " " }}{{ formattedNumber(selectedJob.job_salary_range_to) }}
          </h6>
        </div>
        <div>
          <h6 class="f-light fs-13">Location</h6>
          <h6 class="mb-0" v-for="c in selectedJob.city" :key="c.city_id">
            {{ c.city_name }}
          </h6>
        </div>
      </div>
      <div>
        <p class="fs-15 text-center">
          Your match rate of {{ selectedJob.match_percentage }}% is lower than
          <br />
          other applications recieved
        </p>
        <p class="fs-15 text-center">
          You can however, still make a difference by adding a<br />
          video profile with a relevant and impactful message to<br />
          the employer
        </p>
      </div>

      <div slot="modal-footer">
        <p class="f-coral mb-0 fpn-b pr-3 text-center" style="cursor: pointer" @click="
          $router.push({
            name: 'Job-Application',
            params: { job_id: selectedJob.job_id },
          })
          ">
          I understand, <br />
          continue to apply now
        </p>
        <button class="btn btn-success btn-custom" @click="() => {
          if ($cookies.get('role_id') == 3 && selectedSub && selectedSub.subs_pkg_no_of_videos > 0) {
            $router.push({ name: 'Applicant-Onboarding-4' });
          } else {
            this.$toasted.show('You dont have subscriptions to add video', {
              duration: 6000,
            });
            $router.push({ name: 'Applicant-Settings' });
          }
        }
          ">
          ADD VIDEO PROFILE
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApplicantJobService from "@/apiServices/ApplicantJobServices";
import ApplicantProfileService from "@/apiServices/ApplicantProfileService";
import ApplyJobService from "@/apiServices/ApplyJobServices";
import LocationService from "@/apiServices/LocationServices";
import NavigatorShare from "vue-navigator-share";
import Multiselect from "vue-multiselect";
import FilterService from "@/apiServices/FilterService";
import { SERVERURL } from "@/config";

import moment from "moment";

import { numberWithCommas } from "@/helpers/HelperFunctions.js";
import numeral from 'numeral'

export default {
  name: "GetAllJobs",
  components: {
    NavigatorShare,
    Multiselect,
  },
  props: {
    reRender: {
      type: Boolean,
    },
  },

  data() {
    return {
      SERVERURL: SERVERURL,
      selectedJob: {},
      selectedCountry: null,
      name: "",
      nameState: null,
      search: this.$route.query.search ? this.$route.query.search : "",
      wfh: null,
      appliedJobs: [],
      selectedJob: {},
      isMobileFilter: window.innerWidth <= 1400,
      advanceSearch: this.$route.query.type == "advance",
      selectedCity: null,
      //  selectedCountry: null,
      sort: { label: "Date" },
      jobLength: true,
      jobs: [],
      selectedSub: [],
      days: [],
      cities: [],
      countries: [],

      role_id: this.$cookies.get("role_id"),
      city: null,
      totalCount: 0,
      appDetails: null,
      bookmarkFilter: null,
      sortingOptions: [{ label: "Name" }],
      sortOptions: [{ label: "Date" }, { label: "Name" }],
      // search: "",
      //   appliedJobs: [],
      queryCountry: this.$route.query.country_id || this.$route.query.country_id != "" ? true : false,
      appliedFilter: { filters: [] },
      country_id: this.$route.query.country_id,
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        searchText: this.$route.query.search,
        city_id: this.$route.query.city && this.$route.query.city != "" ? this.$route.query.city : null,
        feature: "Y",
        user_id: this.$cookies.get("user_id"),
        role_id: this.$cookies.get("role_id"),
      },
      selected: [],
      filters: [
        { title: "Work From Home Jobs", checked: false },
        { title: "Country", checkbox: [] },
        { title: "City", checkbox: [] },
        { title: "Speciality", checkbox: [] },
        {
          title: "Employment type",
          checkbox: [
            {
              title: "job_type",
              checkbox: "Full Time",
              checkbox_id: "Full Time",
            },
            {
              title: "job_type",
              checkbox: "Part Time",
              checkbox_id: "Part Time",
            },
            { title: "job_type", checkbox: "Remote", checkbox_id: "Remote" },
            {
              title: "job_type",
              checkbox: "Internship",
              checkbox_id: "Internship",
            },
            {
              title: "job_type",
              checkbox: "Contract",
              checkbox_id: "Contract",
            },
          ],
        },
        { title: "Industry", checkbox: [] },
        {
          title: "Salary Range in USD",
          checkbox: [
            {
              title: "salary",
              checkbox: "500 - 4999",
              checkbox_id: { min: 500, max: 4999 },
            },
            {
              title: "salary",
              checkbox: "5000 - 9999",
              checkbox_id: { min: 5000, max: 9999 },
            },
            {
              title: "salary",
              checkbox: "10000 - 14999",
              checkbox_id: { min: 10000, max: 14999 },
            },
            {
              title: "salary",
              checkbox: "15000 - 19999",
              checkbox_id: { min: 15000, max: 19999 },
            },
            {
              title: "salary",
              checkbox: "20000 - 29999",
              checkbox_id: { min: 20000, max: 29999 },
            },
            {
              title: "salary",
              checkbox: "30000 - 39999",
              checkbox_id: { min: 30000, max: 39999 },
            },
            {
              title: "salary",
              checkbox: "40000 - 49999",
              checkbox_id: { min: 40000, max: 49999 },
            },
            {
              title: "salary",
              checkbox: "50000+",
              checkbox_id: { min: 50000, max: null },
            },
          ],
        },
        {
          title: "Gender",
          checkbox: [
            {
              title: "gender",
              checkbox: "Male",
              checkbox_id: "Male",
            },
            {
              title: "gender",
              checkbox: "Female",
              checkbox_id: "Female",
            },
            {
              title: "gender",
              checkbox: "Other",
              checkbox_id: "Other",
            },
            {
              title: "gender",
              checkbox: "Any",
              checkbox_id: "Any",
            },
          ],
        },
        {
          title: "Date Modified",
          checkbox: [
            {
              title: "date_modified",
              checkbox: "1 Day Ago",
              checkbox_id: 1,
            },
            {
              title: "date_modified",
              checkbox: "7 Days Ago",
              checkbox_id: 7,
            },
            {
              title: "date_modified",
              checkbox: "30 Days Ago",
              checkbox_id: 30,
            },
            {
              title: "date_modified",
              checkbox: "All",
              checkbox_id: null,
            },
          ],
        },
        {
          title: "Career Level",
          checkbox: [
            {
              title: "experience",
              checkbox: "0-2 Years",
              checkbox_id: "0-2",
            },
            {
              title: "experience",
              checkbox: "2-4 Years",
              checkbox_id: "2-4",
            },
            {
              title: "experience",
              checkbox: "4-6 Years",
              checkbox_id: "4-6",
            },
            {
              title: "experience",
              checkbox: "6-8 Years",
              checkbox_id: "6-8",
            },
            {
              title: "experience",
              checkbox: "8-10 Years",
              checkbox_id: "8-10",
            },
            {
              title: "experience",
              checkbox: "10+ Years",
              checkbox_id: "10+",
            },
          ],
        },
        { title: "Shortlist", checked: false },
      ],
    };
  },
  computed: {
    url() {
      return window.location.href;
    },
    title() {
      return "Job";
    },
  },
  watch: {
    // search: function(e) {
    //   if (this.$router.currentRoute.path == "/job-search")
    //     this.$router.push({
    //       path: `/job-search?search=${e}&city=${this.$route.query.city}`,
    //     });
    // },
    // reRender: function(val) {
    //   console.log(`val:::`, val);
    //   if (val) {
    //     this.getApplicantDetails();
    //   }
    // },

    filter: function (e) {
      //   this.appliedFilter.filters = e;
      this.onSelectCountry();
    },
    // appliedJob: function(e) {
    //   this.appliedJobs.forEach((aJob, index) => {
    //     this.jobs.forEach((j, i) => {
    //       if (j.job_id == aJob.job_id) {
    //         this.jobs[i].applied = false;
    //       }
    //     });
    //   });
    // },
  },
  created() {
    addEventListener("resize", () => {
      this.isMobileFilter = innerWidth <= 1400;
    });
  },
  // beforeRouteUpdate(to, from, next) {
  //   // Call the API query method when the URL changes
  //   console.log(`to::::1`, to);
  //   this.getApplicantDetails();
  //   next();
  // },
  beforeMount() {
    //this.getAllJobs(this.pagination);
    if (this.$route.query.country_id && this.$route.query.country_id != "") {
      this.$route.query.country_id.split(",").map((x) => {
        this.appliedFilter.filters.push({
          checkbox: "",
          checkbox_id: +x,
          title: "Country",
        });
      });
    }
    this.getCities(this.pagination);
    // this.getSkills();
    this.getIndustry();
    this.getAllSpeciality();
    this.getSubs();
    this.getCountries();
    this.getApplicantDetails();
  },
  mounted: function () {
    // window.addEventListener("keypress", (e) => {
    //   if (e.key == "Enter") {
    //     this.getAllJobs();
    //   }
    // });
  },
  methods: {
    formattedNumber(sal) {
      //console.log(sal);

      return numeral(sal).format('0,0');
    },
    numberWithCommas: numberWithCommas,
    showFilters: function () {
      document.querySelector(".isMobileFilter").classList.add("show-filter");
      document.querySelector(".navbar").classList.add("navbar-none");
    },
    getAllSpeciality() {
      ApplicantJobService.GetAllSpeciality().then((res) => {
        this.filters[3].checkbox = res.data.data;
      });
    },
    getSubs() {
      const user_id = this.$cookies.get("user_id");
      if (user_id) {
        ApplicantProfileService.getUserSubscription(user_id).then((res) => {
          this.selectedSub = res.data.data.length ? res.data.data[0] : null;
        });
      }
    },

    hideFilters: function () {
      document.querySelector(".isMobileFilter").classList.remove("show-filter");
      document.querySelector(".navbar").classList.remove("navbar-none");
    },
    removeFromFilter() {
      this.appliedFilter.filters.map((x, index) => {
        if (x.title == "City2") {
          this.appliedFilter.filters.splice(index, 1);
        }
      });
    },
    onError(err) { },
    onSuccess(err) { },
    showModal() {
      this.$refs.myModal.show();
    },
    hideModal() {
      this.$refs.myModal.hide();
    },

    resetModal() {
      this.selectedJob = {};
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    async onChangeCheckbox(e) {
      let payload = e;
      // const date_mod = payload.filter((f) => f.title == "date_modified");
      //
      // if (date_mod.length > 1) {
      //   this.appliedFilter.filters.pop();
      // }

      const lastElement = payload[payload.length - 1];

      if (!payload.some((s) => s.title === "Country")) {
        this.cities = [];
        this.filters[2].checkbox = null;
        if (this.selectedCountry && !payload.some((s) => s == this.selectedCountry.checkbox_id)) {
          this.selectedCountry = null;
        }
      }
      payload = payload.map((m) => m.checkbox_id);
      if (lastElement && lastElement.title == "Country") {
        let loader = this.$loading.show();
        try {
          const city = await LocationService.GetAllCityOfCountry(payload);
          this.cities = city.data.data;
          this.filters[2].checkbox = this.cities.map((m) => {
            return {
              title: "City",
              checkbox: m.city_name,
              checkbox_id: m.city_id,
            };
          });
          loader.hide();
        } catch (error) {
          loader.hide();
        }
      }

      //  let loader = this.$loading.show();
      // try {
      //   const city = await LocationService.GetAllCities(payload);
      //
      //   // this.cities = city.data.data;
      //   // const c = await FilterService.GetAllCities();
      //   // this.filters[2].checkbox = c.data.data;
      //   loader.hide();
      // } catch (error) {
      //
      //   loader.hide();
      // }
    },
    handleSubmit() {
      // Exit when the form isn't valid
      // if (!this.checkFormValidity()) {
      //   return;
      // }
      // Push the name to submitted names
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("share-modal");
      });
    },
    getApplicantDetails() {
      const applicant_id = this.$cookies.get("user_id");
      if (this.role_id == 3) {
        let loader = this.$loading.show();
        ApplicantProfileService.getApplicantProfile({ applicant_id })
          .then((res) => {
            this.appDetails = res.data.data.user_data[0];
            if (this.appDetails != undefined) {
              this.appDetails.skills = this.appDetails.skills.map(({ skill_name }) => {
                return skill_name;
              });

              this.filters.map((x) => {
                if (x.title == "Salary Range in USD") {
                  x.title = `Salary Range in ${this.appDetails.currency_code}`;
                }
              });
            }
            this.getAllJobs(this.pagination);
            // this.hideModal();
            loader.hide();
          })
          .catch((err) => {
            loader.hide();
          });
      } else {
        this.getAllJobs(this.pagination);
        //this.hideModal();
      }
    },
    onSelectCity() {
      this.pagination.city_id = null;
    },
    onSelectCountry() {
      const payload = [this.selectedCountry];

      this.appliedFilter.filters = payload[0] ? payload : [];
      this.onChangeCheckbox(this.appliedFilter.filters);
    },
    getAllJobs(paginate = {}) {
      this.getCountries();
      if (Object.keys(paginate).length === 0 && paginate.constructor === Object) {
        this.pagination.current = 1;
        this.pagination.pageSize = 10;
      }
      // if (this.city) {
      //
      //   this.appliedFilter.filters.push({
      //     checkbox: this.city.label,
      //     checkbox_id: this.city.city_id,
      //     title: "City",
      //   });
      // }
      // if (this.selectedCity) {
      //   let found = this.appliedFilter.filters.map((f, index) => {
      //
      //
      //     if (f.title == "City2") {
      //       return { index };
      //     }
      //   });
      //
      //   if (found.length == 0) {
      //     this.appliedFilter.filters.push({
      //       city_name: this.selectedCity.city_name,
      //       city_id: this.selectedCity.city_id,
      //       title: "City2",
      //     });
      //   } else {
      //
      //     this.appliedFilter.filters[
      //       found[0].index
      //     ].city_name = this.selectedCity.city_name;
      //     this.appliedFilter.filters[
      //       found[0].index
      //     ].city_id = this.selectedCity.city_id;
      //   }
      // }

      if (this.selectedCity) {
        let index = this.appliedFilter.filters.findIndex((x) => x.title == "City2");

        if (index < 0) {
          this.appliedFilter.filters.push({
            city_name: this.selectedCity.city_name,
            city_id: this.selectedCity.city_id,
            title: "City2",
          });
        } else {
          this.appliedFilter.filters[index].city_name = this.selectedCity.city_name;
          this.appliedFilter.filters[index].city_id = this.selectedCity.city_id;
        }
      }

      let loader = this.$loading.show();
      const wfh = this.wfh;
      ApplicantJobService.GetAllJobs(
        {
          current: 1,
          pageSize: 10,

          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          search: this.search,
          city_id: this.pagination.city_id ? this.pagination.city_id : null,
          feature: "Y",
          // sort_type: this.sort.label,
          role_id: this.$cookies.get("role_id"),
          user_id: this.$cookies.get("user_id"),
          currency_id: this.appDetails && this.appDetails.currency_id ? this.appDetails.currency_id : null,
          ...paginate,
        },
        {
          filters: this.appliedFilter.filters.length > 0 ? this.appliedFilter.filters : null,
        },
        {
          wfh,
        }
      )
        .then(async (res) => {
          // const data = await res.data.data.forEach((job, index) => {
          //   if (job.job_active === "Y") {
          //     job.applied = true;
          //     job.bookmark = false;
          //     this.jobs.push(job);

          //     //this.getAppliedJobs();
          //   }
          // });

          this.jobs = res.data.data.data;
          this.totalCount = res.data.data.totalCount ? res.data.data.totalCount : 0;

          this.$forceUpdate();
          loader.hide();
          this.getAppliedJobs();
          this.jobs = this.jobs.map((m) => {
            const now = moment();
            const start = moment(m.job_start_date).format("YYYY-MM-DD");
            const diff = now.diff(start, "days");
            return {
              ...m,
              daysAgo: diff,
              deadline: moment(m.job_end_date).format("DD/MM/YYYY"),
            };
          });
          // this.getAppliedJobs();
          // this.daysAgo();
          this.role_id && this.role_id == 3 ? this.getAllBookmark() : null;

          // this.hideModal();
          //this.jobs = [];
          this.jobLength = this.jobs.length > 0 ? true : false;
          if (this.search != "" || (this.appliedFilter && this.appliedFilter.filters.length)) {
            let jobContries = res.data.data.job_countries ? res.data.data.job_countries : [];

            if (!this.queryCountry) {
              this.countries = this.countries.filter((f) => {
                const valid = jobContries.some((s) => s == f.checkbox_id);

                if (valid) {
                  return true;
                }
              });
              this.filters[1].checkbox = this.countries;
            }
          }
        })
        .catch((err) => {
          loader.hide();
        });
    },
    nextPage() {
      this.pagination.current = this.pagination.current + 1;
      this.jobs = [];

      //this.showModal();
      this.getAllJobs(this.pagination);
      this.sort = { label: "Date" };
    },
    PreviousPage() {
      this.pagination.current = this.pagination.current - 1;
      this.jobs = [];
      //  this.showModal();
      this.getAllJobs(this.pagination);
      this.sort = { label: "Date" };
    },
    // getSkills() {
    //   ApplicantProfileService.getAllSkills().then((skill) => {
    //     this.filters[0].checkbox = skill.data.data;
    //   });
    // },
    sortJobs() {
      if (this.sort.label == "Name") {
        const sorting = function (a, b) {
          if (a.job_title < b.job_title) {
            return -1;
          }
          if (a.job_title > b.job_title) {
            return 1;
          }
          return 0;
        };
        this.jobs.sort(sorting);
      }
      if (this.sort.label == "Date") {
        this.getAllJobs(this.pagination);
        // const sortingDate = function(a, b) {
        //   let date1 = moment(a.created_at);
        //   let date2 = moment(b.created_at);
        //   let diff = date2.diff(date1);
        //   if (date2.isAfter(date1)) {
        //     return 1;
        //   } else {
        //     return -1;
        //   }

        //   return 0;
        // };
        // this.days = this.days.sort(function(a, b) {
        //   return a - b;
        // });
        // this.jobs.sort(sortingDate);
      }
    },
    getAllWfhJobs() {
      // this.pagination.wfh = this.wfh;
      //this.getAllJobs(this.pagination);
    },
    getCountries() {
      let loader = this.$loading.show();
      const role_id = this.$cookies.get("role_id");
      FilterService.GetAllCountries({ role_id })
        .then((res) => {
          this.countries = res.data.data;
          this.filters[1].checkbox = res.data.data;

          loader.hide();
        })
        .catch((err) => {
          loader.hide();
        });
    },
    async getCities(payload) {
      // let loader = this.$loading.show();
      // try {
      //   const city = await LocationService.GetAllCities(payload);
      //
      //   this.cities = city.data.data;
      //   const c = await FilterService.GetAllCities();
      //   this.filters[2].checkbox = c.data.data;
      //   loader.hide();
      // } catch (error) {
      //
      //   loader.hide();
      // }
    },

    getIndustry() {
      let loader = this.$loading.show();
      FilterService.GetAllIndustry()
        .then((i) => {
          this.filters[5].checkbox = i.data.data;
          // this.filters[4].checkbox = i.data.data.map((ind) => {
          //   return {
          //     title: "industry",
          //     checkbox: ind.industry_name,
          //     checkbox_id: ind.industry_id,
          //   };
          // });
          loader.hide();
        })
        .catch((err) => {
          loader.hide();
        });
    },
    // getCities(payload) {
    //   LocationService.GetAllCities(payload).then((city) => {
    //     this.cities = city.data.data.map((city) => {
    //       return {
    //         label: city.city_name,
    //         city_id: city.city_id,
    //       };
    //     });
    //   });
    // },
    getAllBookmarkJobs() {
      const user_id = this.$cookies.get("user_id");
      if (!this.bookmarkFilter) {
        //  this.showModal();
        this.jobs = [];
        let loader = this.$loading.show();
        ApplicantJobService.GetBookmark({ user_id })
          .then(async (res) => {
            const data = await res.data.data.forEach((job, index) => {
              if (job.job_active === "Y") {
                // job.applied = true;
                job.bookmark = true;
                this.jobs.push(job);
              }
            });
            this.jobs = this.jobs.map((m) => {
              return {
                ...m,
                deadline: moment(m.job_end_date).format("DD/MM/YYYY"),
              };
            });
            loader.hide();
            //  this.getAppliedJobs();
            // this.daysAgo();
            this.hideModal();
          })
          .catch((err) => {
            loader.hide();
          });
      } else {
        this.jobs = [];
        this.getAllJobs(this.pagination);
        this.hideModal();
      }
    },
    getJobsBookMarkv2() {
      const user_id = this.$cookies.get("user_id");
      if (this.bookmarkFilter) {
        this.showModal();
        this.jobs = [];
        ApplicantJobService.GetBookmark({ user_id }).then(async (res) => {
          const data = await res.data.data.forEach((job, index) => {
            if (job.job_active === "Y") {
              job.applied = true;
              job.bookmark = true;
              this.jobs.push(job);
            }
          });

          this.jobs = this.jobs.map((m) => {
            return {
              ...m,
              deadline: moment(m.job_end_date).format("DD/MM/YYYY"),
            };
          });
          // this.getAppliedJobs();
          // this.daysAgo();
          this.hideModal();
        });
      }
      // else {
      //   this.jobs = [];
      //   this.getAllJobs(this.pagination);
      //   this.hideModal();
      // }
    },

    AddBookmark(job_id, index) {
      const user_id = this.$cookies.get("user_id");
      ApplicantJobService.AddBookmark({ user_id, job_id })
        .then((res) => {
          if (res.data.data.bookmark_active == "Y") {
            this.jobs[index].bookmark = true;
          } else {
            this.jobs[index].bookmark = false;
            this.getJobsBookMarkv2();
          }
        })
        .catch((err) => {
          // this.getSingleBookmark(user_id, job_id);
          // this.jobs[index].bookmark = false;
        });
    },

    getAllBookmark() {
      const user_id = this.$cookies.get("user_id");
      ApplicantJobService.GetBookmark({ user_id }).then((res) => {
        const bm = res.data.data;
        this.jobs.forEach((job, index) => {
          bm.forEach((b) => {
            if (job.job_id == b.job_id) {
              this.jobs[index].bookmark = true;
            } else {
              //this.jobs[index].bookmark = false;
            }
          });
        });
      });
    },
    // getQuery() {
    //   this.pagination.searchText = this.search;
    //   this.pagination.city_id = this.city?.city_id;
    //   this.jobs = [];
    //   ApplicantJobService.GetFilteredJobs(this.pagination).then(async (res) => {
    //     this.jobs = [];
    //     const data = await res.data.data.forEach((job, index) => {
    //       if (job.job_active === "Y") {
    //         job.applied = true;
    //         this.jobs.push(job);
    //       }
    //     });
    //     this.getAppliedJobs();
    //   });
    // },
    onApplyJob(job) {
      this.selectedJob = job;
      if (this.$cookies.get("role_id") == 3) {
        if (job.match_percentage && job.match_percentage < 49) {
          this.$refs["modal"].show();
        } else {
          this.$router.push({
            name: "Job-Application",
            params: { job_id: job.job_id },
          });
        }
      } else {
        this.$router.push({ name: "Register" });
      }
    },

    getAppliedJobs() {
      this.pagination.user_id = this.$cookies.get("user_id");
      if (this.$cookies.get("role_id") == 3) {
        let loader = this.$loading.show();
        ApplyJobService.GetAllAppliedJobs(this.pagination)
          .then((res) => {
            this.appliedJobs = res.data.data.data;
            this.appliedJobs.forEach((aJob, index) => {
              this.jobs.forEach((j, i) => {
                if (j.job_id == aJob.job_id) {
                  this.jobs[i].applied = true;
                }
              });
            });
            loader.hide();
          })
          .catch((err) => {
            loader.hide();
          });
      }
    },
    share(job) {
      this.selectedJob = job;
      this.$bvModal.show("share-modal");
    },
    resetFilters() {
      this.appliedFilter = { filters: [] };
      this.bookmarkFilter = null;
    },
    // appliedFilters() {
    //   this.appliedFilter.user_id = this.$cookies.get("user_id");
    //   this.appliedFilter.role_id = this.$cookies.get("role_id");
    //   Object.assign(this.appliedFilter, this.pagination);
    //   ApplicantJobService.GetAllJobs(this.appliedFilter).then(async (res) => {
    //     this.jobs = [];
    //     this.totalCount = res.data.data.totalCount;
    //     this.bookmarkFilter = false;
    //     const data = await res.data.data.data.forEach((job, index) => {
    //       if (job.job_active === "Y") {
    //         job.applied = true;
    //         this.jobs.push(job);

    //         this.getAppliedJobs();
    //       }
    //     });

    //     this.role_id && this.role_id == 3 ? this.getAllBookmark() : null;
    //   });
    // },
    applyJob(job_id, index) {
      const user_id = this.$cookies.get("user_id");
      const token = this.$cookies.get("AIAPADHASHID");
      const role_id = this.$cookies.get("role_id");
      const payload = { user_id: user_id, job_id: job_id };
      if (token && role_id == 3) {
        ApplyJobService.ApplyJob(payload)
          .then((res) => {
            // this.$bvModal.show("successPage");
            this.jobs[index].applied = true;
            this.$toasted.show("Job Applied Successfully!", {
              position: "top-right",
              duration: 6000,
              icon: "check",
            });
            //this.$router.push({ name: "Job-applied-successfully" });
          })
          .catch((err) => {
            this.jobs[index].applied = false;
            this.$toasted.show("You have already applied for this job", {
              position: "top-right",
              duration: 6000,
              icon: "clear",
            });
          });
      } else {
        this.$router.push("/register");
      }
    },

    showFilters: function () {
      document.querySelector(".isMobileFilter").classList.add("show-filter");
      document.querySelector(".navbar").classList.add("navbar-none");
    },
    hideFilters: function () {
      document.querySelector(".isMobileFilter").classList.remove("show-filter");
      document.querySelector(".navbar").classList.remove("navbar-none");
    },
  },
};
</script>

<style>
.app-job .share-text {
  align-items: start;
}

.multiselect-dropdown span {
  margin-right: 10px;
}
</style>
